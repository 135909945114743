import { WatchOptions } from 'vue';

export default {
  'Zusatzrisiken.mitversicherungMaintenanceNach.select': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request', true);
    }
  },
  'Zusatzrisiken.dauer': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request', true);
    }
  },
  'Zusatzrisiken.monate': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request', true);
    }
  },
  kalkulatorischeRisikenItems: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request', true);
      }
    },
    deep: true,
  },

  'VertraglicheSelbstbeteiligungen.variant1.SelbstbeteiligungMontage.SBBetrag': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request-for-variant', true, 'variant1');
    }
  },

  'VertraglicheSelbstbeteiligungen.variant1.SelbstbeteiligungProbebetrieb.SBBetrag': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request-for-variant', true, 'variant1');
    }
  },

  'VertraglicheSelbstbeteiligungen.variant2.SelbstbeteiligungMontage.SBBetrag': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request-for-variant', true, 'variant2');
    }
  },

  'VertraglicheSelbstbeteiligungen.variant2.SelbstbeteiligungProbebetrieb.SBBetrag': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request-for-variant', true, 'variant2');
    }
  },
  MitversicherungAufErstesRisiko: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request', true);
      }
    },
    deep: true,
  },
  BesondereRisikomerkmale: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request', true);
      }
    },
    deep: true,
  },
  Deckungseinschrankungen: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request', true);
      }
    },
    deep: true,
  },





  priceRequestTarif: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request-for-variant', true, 'tarif');
      }
    },
    deep: true,
  },
} as Record<string, any>;

